import * as React from "react";
import styled from "../lib/styled";

export const PaginateStyles = styled.aside`
  display: flex;
  justify-content: center;
  padding-top: 30px;
  ul {
    list-style: none;
    font-family: "Roboto";
    li {
      display: inline;
      a {
        padding: 10px;
        cursor: pointer;
        color: ${props => props.theme.color.darkThree};

        &:hover {
          color: ${props => props.theme.color.accent};
        }
      }
      &.selected a {
        color: ${props => props.theme.color.accent};
      }
      &.previous,
      &.next {
        display: none;
      }
    }
  }
`;
