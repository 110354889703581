import * as React from "react";
import styled from "styled-components";
import { Row } from "hedron";
import { presets } from "../lib/presets";

export default styled(Row)`
  ${presets.mq.max.tablet} {
    flex-direction: column-reverse;
  }
`;
