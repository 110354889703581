import * as React from "react";
import styled from "../lib/styled";

const BreadcrumbWrapper = styled.div`
  background-color: ${props => props.theme.color.darkOne};
  display: flex;
  align-items: center;
  h1 {
    white-space: nowrap;
    margin: 0;
    font-size: ${props => props.theme.font.size.subhead_1};
    color: ${props => props.theme.color.lightGrey};
    text-shadow: 0 0 10px black;
    letter-spacing: 4px;
    font-family: "Bebas Neue";
    padding: 15px 25px;
  }
`;

const Stripes = styled.div`
  background: repeating-linear-gradient(
    -60deg,
    rgb(67, 67, 67),
    rgb(67, 67, 67) 2px,
    transparent 2px,
    transparent 5px
  );
  height: 35px;
  width: 100%;
  position: relative;

  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 30%;
    height: 100%;
  }
  &:before {
    background: linear-gradient(
      90deg,
      ${props => props.theme.color.darkOne},
      transparent
    );
  }
`;

const Breadcrumb = ({ title }: { title: string }) => {
  return (
    <BreadcrumbWrapper>
      <h1>{title}</h1>

      <Stripes />
    </BreadcrumbWrapper>
  );
};

export default Breadcrumb;
