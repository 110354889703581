import styled from "../lib/styled";

export default styled.div`
  background: repeating-linear-gradient(
    -60deg,
    ${props => props.theme.color.midGrey},
    ${props => props.theme.color.midGrey} 2px,
    transparent 2px,
    transparent 5px
  );
  height: 15px;
  width: 100%;
  position: relative;
  margin: 20px 0;

  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 30%;
    height: 100%;
  }
  &:before {
    background: linear-gradient(
      90deg,
      ${props => props.theme.color.lightGrey},
      transparent
    );
  }
  &:after {
    right: 0;
    background: linear-gradient(
      -90deg,
      ${props => props.theme.color.lightGrey},
      transparent
    );
  }
`;
