import * as React from "react";
import styled from "../lib/styled";
import ReactHtmlParser from "react-html-parser";
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby";
import StripeDivider from "./StripeDivider";
import ArticleMeta from "./ArticleMeta";
import { IFluidImageSizes } from "../types/imageSizes";
import { getExcerpt } from "../lib/getExcerpt";
import { IAuthor } from "../types/wordpressPost";

const ContentHeader = styled.h1`
  font-family: "Bebas Neue";
  margin-top: 0px;
  color: ${(props) => props.theme.color.darkOne};
`;

const FeaturedImage = styled(GatsbyImage)`
  margin: 20px 0;
`;
const ArticleLink = styled(Link)`
  font-family: "Bebas Neue";
  font-size: 25px;
  color: ${(props) => props.theme.color.accent};
`;

const Wrapper = styled.div``;

interface IPostCoverProps {
  author: IAuthor;
  date: string;
  slug: string;
  title: string;
  excerpt: string;
  id: string;
  content: string;
  featuredImage?: {
    node: {
      localFile: IFluidImageSizes;
    };
  };
}

const PostCover = (article: IPostCoverProps) => {
  const featuredImage =
    article?.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData;

  return (
    <Wrapper>
      <Link to={`/${article.slug}`}>
        <ContentHeader>{ReactHtmlParser(article.title)}</ContentHeader>
      </Link>
      <ArticleMeta
        date={article.date}
        author={article.author}
        title={article.title}
        slug={article.slug}
        id={article.id}
      />
      {ReactHtmlParser(getExcerpt(article))}
      {featuredImage && (
        <Link to={`/${article.slug}`}>
          <FeaturedImage alt={ReactHtmlParser(article.title)} image={featuredImage} />
        </Link>
      )}
      <ArticleLink to={`/${article.slug}`}>Read more {">"}</ArticleLink>
      <StripeDivider />
    </Wrapper>
  );
};

export default PostCover;
